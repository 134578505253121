<template>
  <div class="d-flex justify-content-center" id="fq-game-connection-lost">
    <img src="/assets/img/fqgame/oops.png" alt="">
  </div>
</template>

<script>
export default {
  name: 'FQGameConnectionLost'
}
</script>
